import request from '@/utils/request'

export function getUserInfo() {
  return request({
    url: '/userOperation/getUserInfo',
    method: 'post',
    data: {}
  })
}

export function getCompanyMessage() {
  return request({
    url: '/userOperation/getCompanyMessage',
    method: 'post',
    data: {}
  })
}

export function getMySub() {
  return request({
    url: '/userOperation/getMySub',
    method: 'post',
    data: {}
  })
}