<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref } from 'vue'
const activeName = ref('0')
</script>

<template>
    <div class="digiTal">
        <IndexTop></IndexTop>
        <div class="content">
            <div class="content-list">
                <div class="list-left">
                    <img src="@/static/dit/01/1.png" alt="">
                </div>
                <div class="list-center">
                    <div class="center-top">
                        <span>数据治理：《工业企业数字化转型之道》</span>
                        <span></span>
                    </div>
                    <p>数据治理顶层架构规划与设计分为3个阶段:调研与需求分析、顶层架构总体设计、应用场景验证重点在于如何加强数据治理管控，
                        强化组织和制度保障，进一步明确数据全生命周期过程的相关权责实施标准化、规范化、体系化的管理，确保数据生产、使用的全过程受控。
                    </p>
                </div>
                <div class="list-right"></div>
            </div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="查看详情" name="1">
                    <div class="detail">
                        <img src="@/static/dit/01/img/1.png">
                        <img src="@/static/dit/01/img/2.png">
                        <img src="@/static/dit/01/img/3.png">
                        <img src="@/static/dit/01/img/4.png">
                        <img src="@/static/dit/01/img/5.png">
                    </div>
                </el-collapse-item>
            </el-collapse>
            <div class="content-list">
                <div class="list-left">
                    <img src="@/static/dit/02/2.png" alt="">
                </div>
                <div class="list-center">
                    <div class="center-top">
                        <span>数据治理及数据资产化创新实践</span>
                        <span></span>
                    </div>
                    <p style="text-indent: 0;">1、大数据行业市场发展现状和趋势<br>2、数据治理与资产化的挑战<br>3、公司数据治理、数据资产化成果展示<br>4、大数据行业展望</p>
                </div>
                <div class="list-right"></div>
            </div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="查看详情" name="2">
                    <div class="detail">
                        <img src="@/static/dit/02/img/0.png">
                        <img src="@/static/dit/02/img/1.png">
                        <img src="@/static/dit/02/img/2.png">
                        <img src="@/static/dit/02/img/3.png">
                        <img src="@/static/dit/02/img/4.png">
                        <img src="@/static/dit/02/img/5.png">
                        <img src="@/static/dit/02/img/6.png">
                    </div>
                </el-collapse-item>
            </el-collapse>

            
        </div>
        <IndexFoot></IndexFoot>
    </div>
</template>
<style scoped lang="scss">
.digiTal {
    .content {
        min-height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        font-size: 20px;
        width: 70%;
        margin: 0 auto;
        margin-top: 120px;
        .detail {
            display: flex;
            flex-direction: column;
            img{
                 width:100%;
            height: auto;
            }
           
        }
        .content-list {
            padding-right: 20px;
            box-sizing: border-box;
            margin-top:20px;
            border-radius: 5px;
            background: white;
            display: flex;
            height: 200px;

            .list-left {
                width: 200px;
                flex-shrink: 0;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .list-center {
                .center-top {
                    margin-top: 10px;

                    :nth-child(1) {
                        font-size: 22px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    :nth-child(2) {
                        font-size: 14px;
                        font-weight: bold;
                    }

                }

                p {
                    height: 120px;
                    font-size: 15px;
                    text-indent: 30px;
                    overflow-y: auto;
                }
            }
        }
    }
}
</style>
