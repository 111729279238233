const TokenKey = 'company-U-Token'
const uInfoKey = 'company-U-Info'
// const inFifteenMinutes = new Date(new Date().getTime() + 60472400)

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getUserInfo() {
  const info = localStorage.getItem(uInfoKey)
  if (info) {
    return JSON.parse(info)
  } else {
    return null
  }
}

export function setUserInfo(info) {
  return localStorage.setItem(uInfoKey, JSON.stringify(info))
}

export function removeUserInfo() {
  return localStorage.removeItem(uInfoKey)
}

export function isLogin() {
  const token = getToken()
  return !!token
}