<script setup>
import { onMounted, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getTest, subTest } from '@/api/ceping'
import { useRouter } from "vue-router"
const router = useRouter()
const form = reactive({
    resource: []
})
let currentSort = router.currentRoute.value.query.type
const loading = ref(true)
const totalScore = ref(0)
const listData = ref([])
let isAllSelected = true
const pushData = () => {
    form.resource.forEach(item => {
        if (item === '') {
            isAllSelected = false
            ElMessage({
                message: '请选择所有选项',
                type: 'error',
                offset: 200
            })
        } else {
            totalScore.value += parseInt(item)
        }
    })
    if (isAllSelected) {
        subTest({score:totalScore.value,type:currentSort}).then(res => {
            if (res.code === 200) {
                ElMessageBox.alert('提交成功，查看测评结果', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                        router.push({name:'CepingResult',params:{total:totalScore.value}})
                    }
                })
            }
      })
    }
}

onMounted(() => {
    getTest({type: currentSort}).then(res => {
        listData.value = res.data
        form.resource = Array(res.data.length).fill('')
        loading.value = false
    })

})

const radioChange = (e, index) => {
    console.log(e)
    form.resource[index] = e
    console.log(form.resource)
}

</script>
<template>
    <div class="cePing-container">
        <!-- <IndexTop></IndexTop> -->
        <div class="cePing">
            <div class="cePing-title">
                <h1>开始测评</h1>
            </div>
            <el-divider />
            <div class="cePing-content" v-loading="loading">
                <div v-for="(item, index) in listData" :key="index" class="cePing-content-item">
                    <div class="cePing-content-item-title">
                        {{ index + 1 }}、{{ item.title }}
                    </div>
                    <div class="cePing-content-item-content">
                        <el-radio-group v-model="form.resource[index]">
                            <el-radio :value="option.score" @change="() => radioChange(option.score, index)"
                                v-for="(option, optionindex) in item.options" :key="optionindex">
                                {{ option.text }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="tijiao">
                    <el-button v-if="listData.length > 0" type="primary" @click="pushData">提交</el-button>
                    <span v-else>暂无相关测评题目</span>
                </div>

            </div>
        </div>
        <!-- <IndexFoot></IndexFoot> -->
    </div>
</template>
<style scoped lang="scss">
.cePing-container {
    .cePing {
        box-sizing: border-box;
        width: 70%;
        background-color: #fff;
        margin: 30px auto;
        padding: 30px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .cePing-title {
            h1 {
                text-align: center;
                margin: 0;
            }
        }

        .cePing-content {
            .cePing-content-item {
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                &:deep(el-radio){
                    overflow-wrap: break-word !important;
                }
            }

            .tijiao {
                text-align: center;
            }
        }
    }



}
</style>