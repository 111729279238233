import request from '@/utils/request'

export function policyDetail(detailId) {
    return request({
        url: '/policy/policyDetail',
        method: 'post',
        data: {
            id: detailId
        }
    })
}


export function policyContactDetail(detailId) {
    return request({
        url: '/policy/policyContactDetail',
        method: 'post',
        data: {
            id: detailId
        }
    })
}

export function getContactList(contactId) {
    return request({
        url: '/policy/getContactList',
        method: 'post',
        data: {
            contact_id: contactId
        }
    })
}

export function getPolicyList(sortId) {
    return request({
        url: '/policy/sortPolicy',
        method: 'post',
        data: {
            sort_id: sortId
        }
    })
}

export function comsortPolicy(data) {
    return request({
        url: '/policy/comsortPolicy',
        method: 'post',
        data
    })
}

export function getPagePolicy(data) {
    return request({
        url: '/policy/getPagePolicy',
        method: 'post',
        data
    })
}
