<script setup>
</script>

<template>
  <div class="setTing">
    <h2>账号设置</h2>
    <el-divider />
    <div class="info">
            <ul>
                <li>
                    <div class="lileft">
                        <span>昵</span>
                        <span>称</span>
                    </div>
                    <a href="">修改昵称</a>
                </li>
                <li>
                    <div class="lileft">
                        <span>密</span>
                        <span>码</span>
                    </div>
                    <a href="">修改密码</a>
                </li>
                <li>
                    <div class="lileft">
                        <span>手</span>
                        <span>机</span>
                        <span>号</span>
                    </div>
                    <a  href="">修改手机号</a>
                </li>
                <!-- <li>
                    <div class="lileft">
                        <span>邮</span>
                        <span>箱</span>
                    </div>
                    <a  href="">修改邮箱</a>
                </li> -->
                <!-- <li>
                    <div class="lileft">
                        <span>账</span>
                        <span>号</span>
                        <span>注</span>
                        <span>销</span>
                    </div>
                    <a  href="">账号注销</a>
                </li> -->
            </ul>
        </div>
  </div>
</template>

<style scoped lang="scss">  
.setTing{
    display: flex;
    flex-direction: column;
    h2 {
        margin: 0;
    }
    .info {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            
            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                align-items: center;
                a{
                  color: #003E9F;
                  text-decoration: none;
                  font-size: 14px;
                }
                .lileft {
                    width: 80px;
                    display: flex;
                    justify-content: space-between;
                    span {
                        color: #555666;
                        font-size: 14px;
                    }
                    margin-right: 20px;
                }
                .liright {
                    font-size: 14px;
                }

               
            }
        }
    }
}
</style>