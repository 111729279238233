<template>
  <div class="index" v-loading="loading">
    <IndexTop></IndexTop>
    <div class="first-content">
      <div class="first-content-left">
        <el-carousel style="height: 100%;">
          <el-carousel-item v-for="(item, index) in imglist" :key="index">
            <img style="width: 100%;" :src="item.url" alt="" class="elcar-img">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="first-content-right">
        <ul class="right-top" style="color: #003E9F;">
          <li>发改委</li>
          <span @click="$router.push('/news?sortId=7')">更多>></span>
        </ul>
        <ul class="right-bottom">
          <li v-for="(item, index) in fa" :key="index" @click="$router.push(`/newsDetail?id=${item.id}`)">
            <span class="item-title">{{ item.title }}</span>&nbsp;&nbsp;&nbsp;<span>{{ item.create_t }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="second-content" style="margin-top: 20px;">
      <div class="second-content-left">
        <ul class="left-top" style="color: #003E9F;">
          <li>工业和信息化</li>
          <span @click="$router.push('/news?sortId=8')">更多>></span>
        </ul>
        <ul class="left-bottom">
          <li v-for="(item, index) in gong" :key="index" @click="$router.push(`/newsDetail?id=${item.id}`)">
            <span class="item-title">{{ item.title }}</span>&nbsp;&nbsp;&nbsp;<span>{{ item.create_t }}</span>
          </li>
        </ul>
      </div>

      <div class="first-content-right">
        <ul class="right-top" style="color: #003E9F;">
          <li>科学技术</li>
          <span @click="$router.push('/news?sortId=9')">更多>></span>
        </ul>
        <ul class="right-bottom">
          <li v-for="(item, index) in ke" :key="index" @click="$router.push(`/newsDetail?id=${item.id}`)">
            <span class="item-title">{{ item.title }}</span>&nbsp;&nbsp;&nbsp;<span>{{ item.create_t }}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 奖补政策 -->

    <!-- 奖补政策 -->

    <IndexFoot></IndexFoot>
  </div>
</template>
<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref, onMounted } from 'vue'
import { getPolicyList } from '../api/policy'
const fa = ref([])
const imglist = ref([{
  url: require('@/static/policy/1.png')
}, {
  url: require('@/static/policy/2.png')
}, {
  url: require('@/static/policy/3.png')
}
])
const gong = ref([])
const ke = ref([])
const loading = ref(true)
onMounted(() => {
  getPolicyList(7).then(res => {
    fa.value = res.data
  })
  getPolicyList(8).then(res => {
    gong.value = res.data
  })
  getPolicyList(9).then(res => {
    ke.value = res.data
    loading.value = false
  })
})
</script>

<style scoped lang="scss">
.index {
  display: flex;
  flex-direction: column;

  .first-content,
  .second-content {
    width: 1280px;
    margin: 100px auto 0;
    min-height: 400px;
    display: flex;

    /deep/.el-carousel__container {
      height: 100% !important;
    }

    .first-content-left,
    .second-content-left {
      width: 50%;
      margin-right: 20px;
    }

    .first-content-right,
    .second-content-left {
      width: 50%;
      box-sizing: border-box;

      .right-top,
      .left-top {
        box-sizing: border-box;
        align-items: center;
        padding: 5px 0;
        margin: 0;
        display: flex;
        border-bottom: 2px solid #666666;
        list-style-type: none;
        margin-bottom: 15px;
        justify-content: flex-start;

        .active1 {
          color: #003E9F;
        }
      }


      .right-top li,
      .left-top li {
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
        margin-right: 30px;
      }

      .right-top span,
      .left-top span {
        font-size: 14px;
        cursor: pointer;
        margin-left: auto;
      }

      .right-bottom li:hover,
      .left-bottom li:hover {
        color: #003E9F;
        cursor: pointer;
      }

      .right-bottom,
      .left-bottom {
        padding: 0;
        margin: 0;
        list-style-type: none;

        .item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        li {
          margin-bottom: 15px;
          display: flex;
          color: #424242;

          span:nth-child(2) {
            flex-shrink: 0;
            margin-left: auto;
          }
        }

        li:before {
          content: "•";
          color: #424242;
          font-weight: bold;
          display: inline-block;
          width: 2px;
          margin-right: 10px;
        }



      }
    }
  }
}
</style>