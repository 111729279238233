<script setup>
</script>
<template>
    <div class="cePingResult">
        <div class="content">
            <div class="content-left">
                <el-result icon="success" title="测评通过">
                    <template #extra>
                        <span style="font-size: 35px;font-weight: bold;">{{ $route.params.total }}分</span>
                    </template>
                </el-result>
            </div>
            <div class="content-right">
                <span>建议：您的企业满足申请服务要求，前往申请服务吧</span>
            </div>
        </div>
        <div class="go-home">
            <el-button type="primary" @click="$router.push('/')">返回首页</el-button>
            <!-- <el-button type="info" @click="$router.push('/cePing')">重新测评</el-button> -->
            <el-button type="success" @click="$router.push('/subApply')">申请资质认定服务</el-button>
        </div>

    </div>
</template>
<style scoped lang="scss">
.cePingResult {
    width: 50%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 100px auto;

    .go-home {
        text-align: center;
        margin-top: 80px;
    }

    .title {
        text-align: center;
        margin-bottom: 20px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .content-right {
            text-align: center;
            font-size: 14px;
            margin-top: 20px;
        }

        .content-left {
            display: flex;
            align-items: center;

            span:nth-child(1) {
                font-size: 16px;
            }

            span:nth-child(2) {
                font-size: 35px;
                font-weight: bold;
            }
        }

    }
}
</style>