<script setup>
import { reactive } from 'vue'
import { toLogin } from '@/api/user'
import router from '@/router'
import { setToken, setUserInfo } from '@/utils/auth'
import { ElMessageBox } from 'element-plus'
const loginForm = reactive({
    user_phone: '',
    pas: ''
})

function goLogin() {
    if (!loginForm.user_phone) {
        ElMessageBox.alert('请输入手机号')
        return
    }
    if (!loginForm.pas) {
        ElMessageBox.alert('请输入密码')
        return
    }
    toLogin(loginForm).then((response) => {
        if (response.code === 200) {
            setToken(response.token)
            setUserInfo(response.user)
            ElMessageBox.alert('登录成功', '提示', {
                confirmButtonText: '确定',
                callback: () => {
                    router.push('/')
                }
            })
        } else {
            console.log('登录失败')
        }
    })
}


</script>

<template>
    <div class="login-main">
        <div class="login-left">
            <p>河南杨金高科技创业园发展有限公司</p>
            <p>筑数企策工作组</p>
        </div>
        <div class="login-container">
            <div class="login-form">
                <h1 style="text-align: center;">欢迎登录</h1>
                <el-form label-width="100px">
                    <el-form-item label="用户名">
                        <el-input v-model="loginForm.user_phone" clearable placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input type="password" show-password clearable v-model="loginForm.pas"
                            placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="goLogin()">登录</el-button>
                        <el-button type="info" @click="$router.push('/register')">注册</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>

</template>
<style lang="scss" scoped>
.login-main {
    display: flex;
    height: 100vh;

    .login-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        background-image: url('../static/bg.png');
        background-repeat: no-repeat;
        background-size: cover;

        p {
            color: #fff;
            font-size: 34px;
            font-weight: bold;
            font-family: 'Courier New', Courier, monospace;
        }
    }

    .login-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .login-form {
            width: 50%;
        }
    }
}
</style>
