<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref } from 'vue'
const activeName = ref('0')
</script>

<template>
    <div class="ourPartner">
        <IndexTop></IndexTop>
        <div class="content">
            <div class="content-list">
                <div class="list-left">
                    <img src="@/static/jxsk.png" alt="">
                </div>
                <div class="list-center">
                    <div class="center-top">
                        <span>京信数科</span>
                        <span>城市大数据构建、运营和服务商</span>
                    </div>
                    <p>基于大数据+行业创新业务思维核心，依托数据理解、业务理解及数据分析三大能力，通过大数据技术和工具的支撑应用，高效地实现
                        在N个行业领域中融入大数据创新思维与技术，深入挖掘各领域业务沉淀数据的价值，形成创新性管理服务应用，创造数据的无穷价值。</p>
                </div>
                <div class="list-right"></div>
            </div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="查看详情" name="1">
                    <div class="detail">
                        <img src="@/static/partner/1.png">
                        <img src="@/static/partner/2.png">
                        <img src="@/static/partner/3.png">
                        <img src="@/static/partner/4.png">
                        <img src="@/static/partner/5.png">
                        <img src="@/static/partner/6.png">
                        <img src="@/static/partner/7.png">
                    </div>
                </el-collapse-item>
            </el-collapse>

            <div class="content-list">
                <div class="list-left">
                    <img src="@/static/partner/langchao/0.jpg" alt="">
                </div>
                <div class="list-center">
                    <div class="center-top">
                        <span>浪潮云洲工业互联网有限公司</span>
                        <span>工业互联网、数转大师产品</span>
                    </div>
                    <p>浪潮集团是中国领先的云计算、大数据服务商，拥有浪潮信息、浪潮软件、浪潮数字企业3家上市公司。近年来，
                        浪潮集团积极实施硬件、软件、云计算服务“三轮驱动”战略，主要业务涉及计算装备、软件、云计算服务、工业互联网
                        、新一代通信、大数据及若干应用场景。已为全球一百二十多个国家和地区提供IT产品和服务。作为中国最早的IT品牌之一，
                        浪潮始终致力于成为世界一流的新一代信息技术龙头企业，经济社会数字化转型的优秀服务商，新型基础设施建设的骨干企业。
                    </p>
                </div>
                <div class="list-right"></div>
            </div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="查看详情" name="2">
                    <div class="detail">
                        <img src="@/static/partner/langchao/1.jpg">
                        <img src="@/static/partner/langchao/2.jpg">
                        <img src="@/static/partner/langchao/3.jpg">
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <IndexFoot></IndexFoot>
    </div>
</template>
<style scoped lang="scss">
.ourPartner {
    .content {
        min-height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        font-size: 20px;
        width: 70%;
        margin: 0 auto;
        margin-top: 120px;
        .detail {
            display: flex;
            flex-direction: column;
            img{
                 width:100%;
            height: auto;
            }
           
        }
        .content-list {
            padding-right: 20px;
            box-sizing: border-box;
            margin-top:20px;
            border-radius: 5px;
            background: white;
            display: flex;
            height: 200px;

            .list-left {
                width: 200px;
                flex-shrink: 0;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .list-center {
                .center-top {
                    margin-top: 10px;

                    :nth-child(1) {
                        font-size: 22px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    :nth-child(2) {
                        font-size: 14px;
                        font-weight: bold;
                    }

                }

                p {
                    height: 120px;
                    font-size: 15px;
                    text-indent: 30px;
                    overflow-y: auto;
                }
            }
        }
    }
}
</style>
