<template>
    <div>
        <IndexTop></IndexTop>
        <div class="news-detail" v-loading="loading">
            <div class="detail-left">
                <div class="left-title">政策解读</div>
                <div class="left-content">
                    <ul>
                        <li v-for="(item,index) in contactList" :key="index" @click="getDetail(item.id)">
                            <span style="font-weight: bold;">{{ item.title }}</span><br>
                            <span>{{ item.create_t }}</span>
                        </li>
                    </ul>
                    <div class="left_tip" v-if="contactList.length < 1">
                        暂无
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="content-title">{{detailData.title}}</div>
                <div class="content-time">发布时间：{{ detailData.create_t }}</div>
                <el-divider />
                <div class="content-text" v-html="detailData.content">
                </div>
            </div>
        </div>

        <IndexFoot></IndexFoot>
    </div>
</template>

<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { cepingDetail,getjieduList,getjieduDetail} from '@/api/ceping'
import { useRouter } from 'vue-router'
import { ref,onMounted } from 'vue'
const detailData = ref({})
const contactList = ref([])
const loading = ref(true)
const router = useRouter()
onMounted(() => {
    cepingDetail(router.currentRoute.value.query.id).then(res => {
        detailData.value = res.data
        loading.value = false
    })
    getjieduList(router.currentRoute.value.query.id).then(res => {
        contactList.value = res.data
        loading.value = false
    })
})
function getDetail(id) {
    console.log(id)
    getjieduDetail(id).then(res => {
        detailData.value = res.data
    })
}

</script>

<style scoped lang="scss">
.news-detail {
    display: flex;
    margin-top: 120px;
    justify-content:space-around; 
    box-sizing: border-box;
    margin-bottom: -10px;

    .detail-left {
        width: 15%;
        margin-left: 20px;
        .left_tip{
            background-color: #fff;
            height: 50px;
            box-sizing: border-box;
            padding:10px;
            line-height: 30px;
            font-size: 14px;
            text-align: center;
        }
        .left-title {
            text-align: center;
            font-weight: bold;
            font-size:20px;
            padding: 10px 0;
            background-color: #fff;
            border-bottom: 1px solid #f5f5f5;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: #fff;

            li {

                margin-bottom: 10px;
                color: #333;
                min-height: 30px;
                line-height: 30px;
                padding: 5px;
                cursor: pointer;
                font-size: 14px;
                border-bottom: 1px solid #f5f5f5;
            }

            li:hover {
                color: #003E9F;

            }
        }
    }

    .content {
        padding: 20px;
        width: 70%;
        background-color: #fff;
        .content-title {
            text-align: center;
            font-size: 34px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .content-time {
            color: #666666;
            text-align: center;
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
}
</style>