<template>
    <div class="top">
        <!-- <img class="hov" src="@/assets/2.png" alt="" @click="$router.push('/')"> -->
        <span class="hov" @click="$router.push('/')">筑数企策@河南杨金</span>
        <ul class="nav">
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="/news?sortId=7">政策解读</router-link></li>
            <li><router-link to="/partner">合作伙伴</router-link></li>
            <li><router-link to="/member">会员体系</router-link></li>
            <li><router-link to="/about">联系我们</router-link></li>
        </ul>
        <div class="right">
            <span v-if="!isLoginIn"><router-link to="/login">登录</router-link></span>
            <span v-else>
                <router-link to="/myMember">您好，{{userInfo.nickname}}</router-link>
                <span class="out" @click="logout">退出</span>
            </span>
        </div>
    </div>
</template>

<script setup>
import { isLogin, removeToken, removeUserInfo, getUserInfo } from '@/utils/auth'
import { ElMessageBox } from 'element-plus';
import { ref } from 'vue'
import router from '@/router'
const isLoginIn = ref(isLogin())
const userInfo = ref(getUserInfo())
function logout() {
    ElMessageBox.alert('是否确认退出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: () => {
            removeToken()
            removeUserInfo()
            router.push('/login')
        }
    })
}

</script>

<style scoped lang="scss">
.top {
    .hov:hover {
        cursor: pointer;
    }
    .hov {
        font-size: 24px;
        font-weight: bold;
        color: #003E9F;
        font-family: 'Courier New', Courier, monospace;
    }

    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 80px;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    display: flex;
    z-index: 9999;

    img {
        height: 60px;
    }

    .nav {
        display: flex;
        list-style-type: none;

        li {
            width: 90px;
            text-align: center;

            a {
                color: #333;
                text-decoration: none;
            }

            a:hover {
                cursor: pointer;
                color: #003E9F;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;

        a {
            font-size: 16px;
            color: #333;
            margin-right: 20px;
            text-decoration: none;

        }

        a:hover {
            cursor: pointer;
            color: #003E9F;
        }

        .out:hover {
            cursor: pointer;
            color: #003E9F;
        }
    }





}
</style>