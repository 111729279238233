<template>
    <div class="container">
        <div class="title">企业咨询平台网上注册</div>
        <div class="content">
            <el-form label-width="auto">
                <el-form-item label="行业类型">
                    <el-select v-model="ruleForm.company_type" placeholder="请选择行业类型">
                        <el-option v-for="item in company_type_list" :label="item" :value="item" :key="item" />
                    </el-select>
                </el-form-item>
                <el-form-item label="企业名称">
                    <el-input clearable v-model="ruleForm.company" />
                </el-form-item>
                <el-form-item label="联系人">
                    <el-input clearable v-model="ruleForm.name" />
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input clearable v-model="ruleForm.phone" />
                </el-form-item>
                <el-form-item label="密码">
                    <el-input clearable type="password" show-password v-model="ruleForm.pas1" />
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input clearable type="password" show-password v-model="ruleForm.pas2" />
                </el-form-item>

                <el-form-item label="验证码">
                    <el-input style="width: 200px;" clearable v-model="ruleForm.code" />
                    <el-button style="margin-left: 10px;" @click="sendCode()" :disabled="ruleForm.canSend" type="info"
                        size="mini">{{
                        ruleForm.codeMsg }}</el-button>
                </el-form-item>

                <div style="display: flex; justify-content: center;align-items: center;font-size: 14px;">
                    <el-checkbox v-model="checked1" @change="ischecked"></el-checkbox><span style="margin-left: 5px;">
                        我已阅读并同意</span><a href="#" @click.prevent="showPolicyContent()"
                        style="margin-left: 5px;">《注册协议》</a>
                </div>
            </el-form>
            <el-divider content-position="center">
                <el-button type="primary" @click="toRegister()">立即注册</el-button>
            </el-divider>
        </div>
        <el-dialog v-model="ruleForm.showPolicy" title="注册协议" width="800">
            <pre style="text-wrap: wrap;">
<span class="zhuce-title">一、前言</span>
本协议是您（以下称“用户”或“您”）与本公司之间关于使用本公司提供的数字化转型服务的法律协议。在您注册成为本公司客户之前，请仔细阅读本协议。一旦您点击“同意”或完成注册流程，即表示您已充分理解并同意接受本协议的全部内容。
<span class="zhuce-title">二、账户注册</span>
1.用户必须提供真实、准确、完整和最新的注册信息，包括但不限于姓名、联系方式、电子邮件地址等。
2.用户应妥善保管自己的账户密码，不得将账户信息透露给任何第三方。如因用户过失导致账户信息泄露，用户应承担由此产生的一切后果。
3.用户不得利用本公司服务从事任何违法、违规或损害本公司利益的活动。
<span class="zhuce-title">三、服务内容</span>
1.本公司将提供数字化转型相关的服务，包括但不限于数据分析、云计算、人工智能等。
2.用户有权根据本协议及本公司的服务政策，使用本公司提供的服务。
3.本公司有权根据业务需要，随时更新或调整服务内容。对于服务的更新或调整，本公司将通过网站公告或其他方式提前通知用户。
<span class="zhuce-title">四、用户义务</span>
1.用户应遵守国家法律法规，不得利用本公司服务从事任何违法、违规或侵犯他人权益的活动。
2.用户应尊重他人的知识产权，不得在未经授权的情况下使用他人的知识产权。
3.用户应遵守本公司的服务政策，不得利用本公司服务进行任何恶意攻击、骚扰或其他不当行为。
<span class="zhuce-title">五、知识产权</span>
1.本公司拥有或有权使用并提供给用户的所有软件、技术、信息、数据、界面设计、商标、专利、版权等知识产权，均受国家法律法规的保护。
2.用户在使用本公司服务过程中产生的数据，其所有权归属于用户。但用户同意授权本公司在遵守国家法律法规的前提下，为提供服务而合理使用这些数据。
<span class="zhuce-title">六、隐私保护</span>
1.本公司将遵守国家法律法规，采取合理的技术和管理措施，保护用户的个人信息安全。
2.在未经用户明确同意的情况下，本公司不会向任何第三方泄露用户的个人信息。但以下情况除外：
①为履行本协议或提供服务的需要；
②根据法律法规的规定，或行政、司法机关的要求；
③在紧急情况下，为保护用户或他人的合法权益。
<span class="zhuce-title">七、免责条款</span>
1.本公司对于因不可抗力因素（如自然灾害、战争、黑客攻击等）导致的服务中断或数据丢失，不承担任何责任。
2.本公司对于用户在使用服务过程中产生的任何直接、间接、偶然、特殊或惩罚性损失，不承担任何责任。但如因本公司违反国家法律法规或本协议约定，给用户造成损失的，本公司将依法承担相应责任。
<span class="zhuce-title">八、协议变更与终止</span>
1.本公司有权根据业务需要，随时更新或修改本协议。对于协议的更新或修改，本公司将通过网站公告或其他方式提前通知用户。
2.如用户违反本协议或国家法律法规的规定，本公司有权随时终止本协议并关闭用户账户。对于因用户违约导致的一切后果，由用户自行承担。
<span class="zhuce-title">九、争议解决</span>
如因本协议产生任何争议，双方应首先通过友好协商解决；协商不成的，任何一方均有权将争议提交至有管辖权的人民法院诉讼解决。
<span class="zhuce-title">十、其他</span>
1.本协议自用户完成注册并接受本协议之日起生效。
2.本协议的最终解释权归本公司所有。对于本协议未明确规定的事宜，双方可另行协商补充。
</pre>
        </el-dialog>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { register } from '@/api/user'
import router from '@/router'
import { ElMessageBox } from 'element-plus'
const ruleForm = reactive({
    company: '',
    company_type: '',
    name: '',
    phone: '',
    pas1: '',
    pas2: '',
    checked1: false,
    code: '',
    codeMsg: '发送验证码',
    countdown: 60, // 倒计时时间  
    canSend: false,//是否可点击
    showPolicy: false
})
var timer = reactive(null)

const company_type_list = reactive([
    '农、林、牧、渔业',
    '采矿业',
    '制造业',
    '电力、热力、燃气及水生产和供应业',
    '建筑业',
    '交通运输、仓储和邮政业',
    '信息传输、软件和信息技术服务业',
    '批发和零售业',
    '住宿和餐饮业',
    '金融业',
    '房地产业',
    '租赁和商务服务业',
    '科学研究和技术服务业',
    '水利、环境和公共设施管理业',
    '居民服务、修理和其他服务业',
    '教育',
    '卫生和社会工作',
    '文化、体育和娱乐业',
    '公共管理、社会保障和社会组织',
    '国际组织'
])

function showPolicyContent() {
    ruleForm.showPolicy = true
}

function ischecked() {
    ruleForm.checked1 = !ruleForm.checked1
}

function sendCode() {
    if (!ruleForm.canSend) {
        // 模拟发送验证码（这里应该是向服务器发送请求的代码）  
        console.log('发送验证码...')

        // 设置按钮为不可点击，并开始倒计时  
        ruleForm.canSend = true
        ruleForm.codeMsg = `${ruleForm.countdown}秒后重新发送`
        ruleForm.code = '2593'

        // 清除之前的定时器（如果有的话）  
        if (timer) {
            clearInterval(timer)
        }

        // 设置新的定时器  
        timer = setInterval(() => {
            if (ruleForm.countdown > 0) {
                ruleForm.countdown--
                ruleForm.codeMsg = `${ruleForm.countdown}秒后重新发送`
            } else {
                // 倒计时结束，重置按钮状态  
                clearInterval(timer)
                ruleForm.countdown = 60
                ruleForm.codeMsg = '发送验证码'
                ruleForm.canSend = false
            }
        }, 1000)
    }
}

function toRegister() {
    let params = {
        company: ruleForm.company,
        contact_people: ruleForm.name,
        user_phone: ruleForm.phone,
        pas: ruleForm.pas1,
        company_type: ruleForm.company_type
    }
    if (!ruleForm.company_type) {
        ElMessageBox.alert('请选择行业类型', '提示', {
            confirmButtonText: '确定'
        })
        return
    }


    if (!ruleForm.company) {
        ElMessageBox.alert('请输入企业名称', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    if (!ruleForm.name) {
        ElMessageBox.alert('请输入联系人', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    if (!ruleForm.phone) {
        ElMessageBox.alert('请输入联系电话', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    if (!ruleForm.pas1) {
        ElMessageBox.alert('请输入密码', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    if (ruleForm.pas1 !== ruleForm.pas2) {
        ElMessageBox.alert('两次输入的密码不一致，请重新输入', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    if (!ruleForm.code) {
        ElMessageBox.alert('请输入验证码', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    if (!ruleForm.checked1) {
        ElMessageBox.alert('请先阅读并同意注册协议', '提示', {
            confirmButtonText: '确定'
        })
        return
    }
    register(params).then((response) => {
        if (response.code === 200) {
            ElMessageBox.confirm('注册成功，是否前往登录？', '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    router.push('/login')
                })
        }
    })
}
</script>

<style scoped lang="scss">
.container {
    .zhuce-title {
        font-size: 14px;

        font-weight: bold;
    }

    .title {
        font-size: 26px;
        background: #409eff;
        font-weight: bold;
        color: white;
        text-align: center;
        height: 80px;
        line-height: 80px;
    }

    .content {
        width: 60%;
        margin: 20px auto;
        background: #fff;
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 50px 20px;

        .el-form {
            max-width: 600px;
            margin: 0 auto;

            a {
                text-decoration: none;
                color: #409eff;
            }
        }

        .el-divider {
            margin-top: 60px;
        }
    }
}
</style>