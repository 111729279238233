<template>
    <div class="sub-apply">
        <h1>申请表</h1>
        <el-divider />
        <div class="btn-group">
            <a href="/#/subApply">申请表模板下载</a>
            <a href="/#/subApply">申请表上传</a>
        </div>
        <p>注：请将填写好的申请表上传，并填写完整，以便审核。审核通过后，将会给您发送通知，请注意查收。</p>
        <div class="btn">
            <el-button type="primary" size="medium" @click="handleClick">提交申请</el-button>
        </div>
    </div>
</template>

<script setup>
function handleClick() {
    alert('提交成功');
    window.location.href = '/#/';
}
</script>

<style scoped lang="scss">
.sub-apply {
    padding: 20px;
    background-color:white;
    width: 60%;
    margin: 0 auto;
    margin-top: 40px;
    h1{
        margin: 0;
        text-align: center;
    }
    .btn-group{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .btn{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}
</style>