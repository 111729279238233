<script setup>
import { ref,onMounted } from 'vue'
import { getCompanyMessage } from '../api/useroperation'
const uInfo = ref({
})

onMounted(() => {
    getCompanyMessage().then(res => {
        uInfo.value = res.data
  })
})
</script>

<template>
    <div class="myMessage">
        <h2>基本信息</h2>
        <el-divider />
        <div class="info">
            <ul>
                <li>
                    <div class="lileft">
                        <span>企</span>
                        <span>业</span>
                        <span>名</span>
                        <span>称</span>
                    </div>

                    <div class="liright">{{ uInfo.company }}</div>
                </li>
                <li>
                    <div class="lileft">
                        <span>联</span>
                        <span>系</span>
                        <span>人</span>
                    </div>
                    <div class="liright">{{ uInfo.contact_people }}</div>
                </li>
                <li>
                    <div class="lileft">
                        <span>手</span>
                        <span>机</span>
                        <span>号</span>
                    </div>
                    <div class="liright">{{ uInfo.user_phone }}</div>
                </li>
                <!-- <li>
                    <div class="lileft">
                        <span>邮</span>
                        <span>箱</span>
                    </div>
                    <div class="liright">23434324234@qq.com</div>
                </li> -->
                <li>
                    <div class="lileft">
                        <span>企</span>
                        <span>业</span>
                        <span>地</span>
                        <span>址</span>
                    </div>
                    <div class="liright">未完善</div>
                </li>
            </ul>
        </div>
        <!-- <h2>企业荣誉</h2>
        <el-divider />
        <div class="info">
            <ul>
                <li>
                
                    <div class="liright">2021年获得“优秀服务奖”</div>
                </li>
                <li>
                    
                    <div class="liright">2022年获得地区发展奖</div>
                </li>
                <li>
                   
                    <div class="liright">2023年获得企业优秀奖</div>
                </li>
            </ul>
        </div> -->
    </div>
</template>


<style scoped lang="scss">
.myMessage {
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0;
    }

    .info {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            
            li {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 30px;
                align-items: center;

                .lileft {
                    width: 80px;
                    display: flex;
                    justify-content: space-between;
                    span {
                        color: #555666;
                        font-size: 14px;
                    }
                    margin-right: 20px;
                }
                .liright {
                    flex: 1;
                    font-size: 14px;
                }

               
            }
        }
    }
}
</style>