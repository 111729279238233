<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script setup>

</script>

<style scoped>
.app {
  margin: 0;
  padding: 0;
}
</style>
