<template>
    <div class="beforecePing" v-loading="loading">
        <h1>测评相关政策</h1>
        <el-divider />
        <block v-if="comNewsList.length > 0">
            <ul>
                <li v-for="item in comNewsList" :key="item" @click="$router.push(`/cepingDetail?id=${item.id}`)">{{
        item.title }}</li>
            </ul>
            <div class="content-page">
                <el-pagination v-model:current-page="currentPage1" :page-size="pageSize" :small="small" :disabled="disabled"
                    :background="background" layout="total, prev, pager, next" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </block>
        <span v-else style="margin-bottom: 50px;">暂无</span>
        <el-button type="primary" @click="tocePing">开始测评</el-button>
    </div>
</template>

<script setup>
import { getCepingPolicy,getAllCepingPolicy } from '@/api/ceping'
import { ref, onMounted } from "vue"
import { useRouter } from "vue-router"
const router = useRouter()
const comNewsList = ref([])
const currentSort = ref()
const currentPage1 = ref(1)
const total = ref(0)
const pageSize = ref(10)
const loading = ref(true)
onMounted(() => {
    currentSort.value = router.currentRoute.value.query.type
    let params = {
        contact_id:router.currentRoute.value.query.type,
        current_page:currentPage1.value,
        page_size:pageSize.value
    }
    getCepingPolicy(params).then(res => {
        comNewsList.value = res.data
    })
    let params1 = {
        contact_id:router.currentRoute.value.query.type
    }
    getAllCepingPolicy(params1).then(res => {
        total.value = res.data.length
        loading.value = false
    })

})

function handleSizeChange(size){
    console.log(111,size)
}

function handleCurrentChange(){
    let params = {
        contact_id:router.currentRoute.value.query.type,
        current_page:currentPage1.value,
        page_size:pageSize.value
    }
    getCepingPolicy(params).then(res => {
        comNewsList.value = res.data
    })
}

function tocePing() {
    router.push(`/cePing?type=${currentSort.value}`)
}
</script>

<style scoped lang="scss">
.beforecePing {
    box-sizing: border-box;
    width: 70%;
    background-color: #fff;
    margin: 30px auto;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-page {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        color: black;
        text-align: center;
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 15px;
        }

        li:hover {
            color: #409eff;
            cursor: pointer;
        }
    }
}
</style>