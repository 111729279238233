import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

import IndexHome from '../views/indexHome.vue'
import ComNews from '../views/comNews.vue'
import NewsDetail from '../views/newsDetail.vue'
import About from '../views/aboutUs.vue'
import CePing from '../views/cePing.vue'
import CepingResult from '../views/cePingResult.vue'
import SubApply from '../views/subApply.vue'
import PersonalCenter from '../views/personalCenter.vue'
import MyMessage from '../components/myMessage.vue'
import MyCeping from '../components/myCeping.vue'
import ServerSub from '../components/serverSub.vue'
import SetTing from '../components/setTing.vue'
import MyMember from '../components/myMember.vue'
import Login from '../views/toLogin.vue'
import BeforecePing from '../views/beforecePing.vue'
import PolicyJie from '../views/policyJie.vue'
import Register from '../views/toRegister.vue'
import ourPartner from '../views/ourPartner.vue'
import Member from "../views/memBer.vue"
import Digital from "../views/digiTal.vue"
import cepingDetail from '../views/cepingDetail.vue'

const routes = [
  {
    path: '/',
    component: IndexHome
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/news',
    component: ComNews
  },
  {
    path: '/partner',
    component: ourPartner
  },
  {
    path: '/digital',
    component: Digital
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path:'/member',
    name:'Member',
    component:Member
  },
  {
    path: '/cepingDetail',
    name: 'cepingDetail',
    component: cepingDetail
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: NewsDetail
  }, {
    path: '/ceping',
    name: 'Ceping',
    component: CePing
  },{
    path: '/policyJie',
    name: 'PolicyJie',
    component: PolicyJie
  },
  {
    path: '/beforeceping',
    name: 'BeforecePing',
    component: BeforecePing
  },
  {
    path: '/cepingResult/:total',
    name: 'CepingResult',
    component: CepingResult
  },
  {
    path: '/subApply',
    name: 'SubApply',
    component: SubApply
  },
  {
    path: '/personalCenter',
    name: 'PersonalCenter',
    component: PersonalCenter,
    children: [
      {
        path: '/myMember',
        name: 'myMember',
        component: MyMember
      },
      {
        path: '/myMessage',
        name: 'myMessage',
        component: MyMessage
      },

      {
        path: '/myCeping',
        name: 'myCeping',
        component: MyCeping
      },

      {
        path: '/serverSub',
        name: 'serverSub',
        component: ServerSub
      },


      {
        path: '/setTing',
        name: 'setTing',
        component: SetTing
      }
    ]
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {  
  window.scrollTo(0, 0);  
  next();  
});  

export default router