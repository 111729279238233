<script setup>
</script>

<template>
  <div class="serverSub">
    <div class="list" v-for="(item, index) in 1" :key="index">
      <div class="left">
        <div class="item">
          <span class="lileft">
            服务类型：
          </span>

          <span class="liright">
            高新技术企业
          </span>
        </div>
        <div class="item">
          <span class="lileft">
            申请时间：
          </span>

          <span class="liright">
            2022年1月11日
          </span>
        </div>
        <div class="item">
          <span class="lileft">
            审批结果：
          </span>

          <span class="liright">
            已通过
          </span>
        </div>
        <div class="item">
          <span class="lileft">
            服务进度：
          </span>
          <span class="liright">
            服务中
          </span>
        </div>

      </div>
      <div class="right">
        <el-button type="primary" @click="$router.push('subApply')">重新申报</el-button>
      </div>
    </div>

  </div>
</template>


<style scoped lang="scss">  
.serverSub {
  display: flex;
  flex-direction: column;

  .list {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 15px;
    padding-bottom: 15px;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;

        .lileft {
          width: 80px;
          display: flex;
          margin-right: 20px;
          justify-content: space-between;
          color: #555666;
          font-size: 14px;
        }

        .liright {
          flex: 1;
          font-size: 14px;

        }
      }
    }
  }
}
</style>