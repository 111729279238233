<script setup>
import { onMounted, ref } from 'vue'
import { getMySub } from '@/api/useroperation'

const subList = ref([])

onMounted(async () => {
  const res = await getMySub()
  subList.value = res.data
})
</script>

<template>
  <div class="myCeping">
    <div class="list" v-for="(item, index) in subList" :key="index">
      <div class="left">
        <div class="item">
          <span class="lileft">
            测评类型：
          </span>

          <span class="liright">
            {{ item.title }}
          </span>
        </div>
        <div class="item">
          <span class="lileft">
            测评时间：
          </span>

          <span class="liright">
            {{item.create_t}}
          </span>
        </div>
        <div class="item">
          <span class="lileft">
            测评分数：
          </span>

          <span class="liright">
            {{item.score}}分
          </span>
        </div>
        <div class="item">
          <span class="lileft">
            测评结果：
          </span>

          <span class="liright">
            符合申报服务要求
          </span>
        </div>

      </div>
      <div class="right">
        <el-button type="primary" @click="$router.push('subApply')">申请服务</el-button>
        <el-button style="margin-left: 0;" type="info" @click="$router.push(`ceping?type=${item.type}`)">重新测评</el-button>
      </div>
    </div>

  </div>
</template>


<style scoped lang="scss">
.myCeping {
  display: flex;
  flex-direction: column;

  .list {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 15px;
    padding-bottom: 15px;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;

        .lileft {
          width: 80px;
          display: flex;
          margin-right: 20px;
          justify-content: space-between;
          color: #555666;
          font-size: 14px;
        }

        .liright {
          flex: 1;
          font-size: 14px;

        }
      }






    }
  }
}
</style>