import request from '@/utils/request'

export function register(data){
  return request({
    url: '/user/register',
    method: 'post',
    data
  })
}

export function toLogin(data){
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function getUserInfo(){
  return request({
    url: '/user/getUserInfo',
    method: 'post',
    data:{}
  })
}
