<template>
    <div class="foot">
        <div class="foot-content">
            <div class="foot-left">
                <span>联系我们</span>
                <p>联&nbsp;&nbsp;系&nbsp;人：任经理</p>
                <p>联系电话：19937886456</p>
                <p>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：郑州市金水区鸿苑路金科智慧谷1号楼2层</p>
                <img src="@/static/contact_people.jpg" alt="微信扫一扫">
            </div>
            <div class="foot-right">
                <div class="right-item">
                    <span>关于我们</span>
                    <ul>
                        <li><a href="#" @click.prevent="$router.push('/about')">公司简介</a></li>
                    </ul>
                </div>
                <div class="right-item">
                    <span>解决方案</span>
                    <ul>
                        <li><a href="#">专精特新企业申报服务</a></li>
                    </ul>
                </div>
                <div class="right-item">
                    <span>客户案例</span>
                    <ul>
                        <li><a href="#">专精特新企业申报服务案例</a></li>
                    </ul>
                </div>
            </div>
            <div class="liuyan">
                <span>留言</span>
                <form>
                    <input v-model="formData.name" type="text" placeholder="请输入您的姓名（必填）">
                    <input v-model="formData.phone" type="text" placeholder="请输入您的手机号（必填）">
                    <input v-model="formData.company" type="text" placeholder="请输入您的企业名称">
                    <textarea v-model="formData.content" placeholder="请输入您的留言，我们会尽快与您联系。（必填）"></textarea>
                    <button type="button" @click="subMessage">提交</button>
                </form>
            </div>
        </div>

        <div class="foot-bottom">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">豫ICP备2024082536号</a>
        </div>
        <el-backtop :right="6" :bottom="50" />

        <!-- 智能客服 -->
        <div class="intelligence" @click="visible = true" v-show="!visible">
            <img src="@/static/intelligence.png" alt="智能客服" />
            <span>智</span>
            <span>能</span>
            <span>客</span>
            <span>服</span>
        </div>
        <div class="message-outer" v-show="visible">
            <div class="top">
                <span>筑数企策@河南杨金企业服务平台</span>
                <img src="@/static/close.png" class="close" alt="关闭" @click="visible = false" />
            </div>
            <!-- 显示聊天消息的容器 -->
            <div class="message-container">
                <div v-for="message in messages" :key="message.id" class="message">
                    <div v-if="message.isMe" class="you">
                        <img src="@/static/you.png" alt="智能客服">
                        <div>{{ message.text }}</div>
                    </div>
                    <div v-else class="me">
                        <div>{{ message.text }}</div>
                        <img src="@/static/me.png" alt="用户头像">
                    </div>
                </div>
                <div v-if="isGetMessage" class="you">
                        <div>智能回复中...</div>
                </div>
            </div>
            <!-- 输入消息的表单 -->
            <div class="bot">
                <textarea class="bot-input" v-model="inputText" placeholder="请输入消息"></textarea>
                <div class="bot-btn">
                    <button @click="sendMsg">发送</button>
                </div>
            </div>
        </div>
        <!-- 智能客服 -->


    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import { insertMessage } from "@/api/message"
import { ElMessageBox } from "element-plus"
import { getAixFun } from '../api/ai'
let formData = ref({
    name: "",
    phone: "",
    company: "",
    content: ""
})
let visible = ref(false);
let isGetMessage = ref(false);
let inputText = ref("");
let messages = reactive([
    {
        id: 1,
        text: "您好，欢迎使用筑数企策@河南杨金企业服务平台，请问有什么需要帮助?",
        isMe: true,
    }
])

function sendMsg() {
    if (!inputText.value) {
        ElMessageBox.alert('请输入消息内容', '提示', {
            confirmButtonText: '确定'
        })
        return
    } else {
        messages.push({
            id: messages.length + 1,
            text: inputText.value,
            isMe: false,
        })
        isGetMessage.value = true
        getAixFun({ question: inputText.value }).then(res => {
            isGetMessage.value = false
            messages.push({
                id: messages.length + 1,
                text: res.data,
                isMe: true,
            })
        })
    }
    inputText.value = "";
}
function subMessage() {
    if (!formData.value.name) {
        alert("请填写您的姓名")
        return
    }
    if (!formData.value.phone) {
        alert("请填写您的手机号")
        return
    }
    if (!formData.value.content) {
        alert("请填写您的留言内容")
        return
    }
    insertMessage(formData.value).then(() => {
        ElMessageBox.alert('留言提交成功,我们会尽快联系您', '提示', {
            confirmButtonText: '确定',
        })
        formData.value = {
            name: "",
            phone: "",
            company: "",
            content: ""
        }
    })
}
</script>

<style scoped lang="scss">
.foot {
    box-sizing: border-box;
    padding: 50px 0 20px 0;
    margin-top: 50px;
    width: 100%;
    background-color: #fff;

    .foot-content {
        display: flex;
        justify-content: center;

        .liuyan {
            width: 200px;
            display: flex;
            flex-direction: column;

            span {
                display: inline-block;
                font-weight: bold;
                margin-bottom: 15px;
                padding-bottom: 10px;
            }

            input {
                margin-bottom: 15px;
                width: 100%;
                border: none;
                border-bottom: 1px solid #ccc;
                background-color: #f5f5f5;
                outline: none;
                border: 1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
                height: 30px;
                box-sizing: border-box;
                padding: 0 10px;
                font-size: 14px;
            }

            input::placeholder {
                font-size: 12px;
                color: #666666;
            }

            textarea::placeholder {
                font-size: 12px;
                color: #666666;
            }

            textarea {
                outline: none;
                height: 200px;
                margin-bottom: 15px;
                width: 100%;
                background-color: #f5f5f5;
                box-sizing: border-box;
                padding: 10px;
            }

            button {
                width: 100%;
            }
        }

        .foot-left {
            img {
                width: 100px;
                height: 100px;
            }

            span {

                font-weight: bold;
                padding-bottom: 10px;
            }

            p {
                color: #666666;
                font-size: 14px;
            }
        }

        .foot-right {
            display: flex;
            justify-content: space-around;
            width: 40%;
            margin: 0 100px;

            .right-item {
                display: flex;
                flex-direction: column;

                span {
                    font-weight: bold;
                    margin-bottom: 15px;

                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                li {
                    margin-bottom: 5px;
                }

                li a {
                    font-size: 14px;
                    text-decoration: none;
                    color: #666666;
                }

                a:hover {
                    cursor: pointer;
                }
            }

        }
    }

    .foot-bottom {
        margin-top: 50px;
        text-align: center;
        color: #666666;
        font-size: 14px;
        a{
            text-decoration: none;
            color:#666666;
        }
    }
}

.message-outer {
    width: 300px;
    position: fixed;
    bottom: 20px;
    right: 60px;
    border: 1px solid #ccc;

    .top {
        padding: 10px;
        color: #fff;
        box-sizing: border-box;
        background-color: rgb(106, 106, 241);
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .message-container {
        background-color: #f5f5f5;
        height: 300px;
        padding: 10px;
        overflow-y: scroll;
        font-size: 12px;

        .message {
            width: 100%;
            display: flex;
            flex-direction: column;

            .you,
            .me {
                margin-bottom: 10px;
                display: flex;

                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                div {
                    background-color: #fff;
                    border-radius: 5px;
                    padding: 5px;
                }
            }

            .you {
                width: 70%;

                div {
                    margin-left: 5px;
                }
            }

            .me {
                align-self: flex-end;
                justify-content: flex-end;
                width: 70%;

                div {
                    margin-right: 5px;
                }
            }

        }
    }

    .bot {
        background-color: #fff;

        .bot-input {
            font-size: 12px;
            width: calc(100% - 5px);
            height: 80px;
            outline: none;
            border: none;
            color: #666666;
            box-sizing: border-box;
            padding: 10px;
        }

        .bot-input::placeholder {
            color: #666666;
            font-size: 12px;
        }

        .bot-btn {
            display: flex;
            justify-content: flex-end;

            button {
                background-color: #fff;
                cursor: pointer;
                font-size: 12px;
                margin-right: 10px;
                border-radius: 5px;
                margin-bottom: 10px;
                border: 1px solid #ccc;
            }
        }
    }

}

.intelligence {
    position: fixed;
    right: 0;
    bottom: 100px;
    display: flex;
    color: #fff;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    border-radius: 5px;
    cursor: pointer;
    background: rgb(106, 106, 241);

    img {
        width: 30px;
        height: 30px;
    }
}
</style>