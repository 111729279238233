import request from '@/utils/request'

export function getCepingTimes(){
  return request({
    url: '/userOperation/getCepingTimes',
    method: 'post',
    data:{}
  })
}

export function getTest(data){
  return request({
    url: '/clientCePing/getTest',
    method: 'post',
    data
  })
}

export function subTest(data){
  return request({
    url: '/clientCePing/submitTest',
    method: 'post',
    data:data
  })
}

export function getCepingPolicy(data) {
  return request({
      url: '/ceping/getCepingPolicy',
      method: 'post',
      data
  })
}

export function getAllCepingPolicy(data) {
  return request({
      url: '/ceping/getAllCepingPolicy',
      method: 'post',
      data
  })
}

export function cepingDetail(detailId) {
  return request({
      url: '/ceping/cepingDetail',
      method: 'post',
      data: {
          id: detailId
      }
  })
}

export function getjieduList(contact_id) {
  return request({
      url: '/ceping/getjieduList',
      method: 'post',
      data: {
        contact_id: contact_id
      }
  })
}

export function getjieduDetail(detailId) {
  return request({
      url: '/ceping/getjieduDetail',
      method: 'post',
      data: {
          id: detailId
      }
  })
}
