<template>
  <div class="index" v-loading="loading">
    <IndexTop></IndexTop>
    <div style="margin-top: 60px;"></div>
    <div class="elcar" v-for="(item, index) in homeImg" :key="index">
      <el-carousel style="height: 100%;" indicator-position="none" arrow="never">
        <el-carousel-item>
          <router-link :to="item.url">
            <div class="elcar-img2">
              <img :src="item.img_url" alt="">
               <!-- <img src="@/static/1.png"> -->
              <!-- <span>{{ item.title }}</span> -->
            </div>
          </router-link>
        </el-carousel-item>
      </el-carousel>
    </div>
    <h1>企业资质测评</h1>
    <div class="new-content">
      <div class="content-list" v-for="(item, index) in newcontent" :key="index" @click="goToCeping(item.url)">
        <span>{{ item.title }}</span>
      </div>
    </div>
    <IndexFoot></IndexFoot>
  </div>
</template>

<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref, onMounted } from 'vue'
import { ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
import { getHomeImg } from '@/api/home'
import { getCepingTimes } from '@/api/ceping'
const router = useRouter()
const homeImg = ref([])
const loading = ref(true)
const cepingTimes = ref(0)

const newcontent = ref([
  {
    title: '国家科技型中小企业',
    img: '@/static/ce/00.jpg',
    url: '/beforecePing?type=1'
  },
  {
    title: '国家高新技术企业',
    img: '@/assets/11.jpg',
    url: '/beforecePing?type=3'
  },
  {
    title: '省级工程技术研究中心',
    img: '@/assets/00.jpg',
    url: '/beforecePing?type=5'
  },
  {
    title: '专精特新',
    img: '@/assets/00.jpg',
    url: '/beforecePing?type=4'
  },
  {
    title: '科技贷',
    img: '@/assets/00.jpg',
    url: '/beforecePing?type=6'
  },
  {
    title: '数据资产',
    img: '@/assets/00.jpg',
    url: '/beforecePing?type=7'
  }
])
onMounted(() => {
  getHomeImg().then(res => {
    homeImg.value = res.data
    loading.value = false
  })

  getCepingTimes().then(res => {
    cepingTimes.value = res.data.ceping_times
  })
})

function goToCeping(url) {
  if (cepingTimes.value > 0) {
    router.push(url)
  } else {
    ElMessageBox.alert('您的测评次数已用完，请升级会员后继续使用测评服务', '提示', {
      confirmButtonText: '确定',
      callback: () => {

      }
    })
  }
}
</script>

<style scoped lang="scss">
.new-content {
  background: url('@/static/ce/55.jpg') no-repeat;
  width: 70%;
  margin: 0 auto;
  display: flex;
  height: 60px;

  .content-list {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: boder-box;
    padding: 5px;

    span {
      font-size: 15px;
      color: #fff;
      font-weight: bold;
    }
  }

  .content-list:hover {
    transform: scale(1.02);
    cursor: pointer;
    box-shadow: 0 0 15px #b1b1b1;
  }
}

.elcar {
  margin-top: 20px;
  height: calc(100vh - 80px);

  a {
    text-decoration: none;
  }
}

/deep/.el-carousel__container {
  height: 100% !important;
}

.elcar-img1,
.elcar-img2,
.elcar-img3 {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #003E9F;
  font-size: 50px;
  font-weight: bold;
}

.elcar-img2 img {
  display: block;
  width: 100%;
  height: auto;
}

.elcar-img2 span {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}

.index {
  display: flex;
  flex-direction: column;

  .first-content,
  .second-content {
    width: 1280px;
    margin: 100px auto 0;
    min-height: 400px;
    display: flex;

    .first-content-left,
    .second-content-left {
      width: 50%;
      margin-right: 20px;
    }

    .first-content-right,
    .second-content-left {
      margin-left: 10px;
      width: 50%;
      box-sizing: border-box;

      .right-top,
      .left-top {
        box-sizing: border-box;
        align-items: center;
        padding: 5px 0;
        margin: 0;
        display: flex;
        border-bottom: 2px solid #666666;
        list-style-type: none;
        color: #666666;
        margin-bottom: 15px;
        justify-content: space-around;
      }


      .right-top li,
      .left-top li {
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
      }

      .right-top span,
      .left-top span {
        font-size: 14px;
        cursor: pointer;
      }

      .right-bottom li:hover {
        color: #003E9F;
        cursor: pointer;
      }

      .right-bottom,
      .left-bottom {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin-bottom: 15px;
          display: flex;
          color: #424242;

          span:nth-child(2) {
            margin-left: auto;
          }
        }

        li:before {
          content: "•";
          color: #424242;
          font-weight: bold;
          display: inline-block;
          width: 2px;
          margin-right: 10px;
        }
      }
    }
  }
}

h1 {
  margin-bottom: 30px;
  text-align: center;
}

.index-content {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 400px;
}

.content-left {
  width: 620px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.left-list1 span,
.left-list2 span,
.left-list3 span,
.left-list4 span {
  color: white;
  font-weight: bold;
  font-size: 26px;
}

.left-list1:hover,
.left-list2:hover,
.left-list3:hover,
.left-list4:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0 0 15px #b1b1b1;
}

.left-list1 {
  /* width: 240px; */
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
  background: url(../assets/33.png);
  height: 190px;
}

.left-list2 {
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
  background: url(../assets/77.jpg);
  height: 190px;
}

.left-list3 {
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
  background: url(../assets/55.jpg);
  height: 190px;
  align-self: flex-end;
}

.left-list4 {
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
  background: url(../assets/66.jpg);
  height: 190px;
  align-self: flex-end;
}

.content-right {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.right-list1 {
  box-sizing: border-box;
  padding: 20px;
  width: 190px;
  background: url(../assets/00.jpg);
}

.right-list1 span,
.right-list2 span {
  color: white;
  font-weight: bold;
  font-size: 26px;
}

.right-list1:hover,
.right-list2:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0 0 15px #b1b1b1;
}

.right-list2 {
  box-sizing: border-box;
  padding: 20px;
  width: 190px;
  background: url(../assets/11.jpg);
}
</style>
