<template>
    <div class="personal-center">
        <!-- -->
        <IndexTop></IndexTop>
        <!-- -->
        <div class="personal-center-content">

            <div class="center-left">
                <ul>
                    <li v-for="(item, index) in  sidearr " :key="index" @click="activeIndex = index" :class="{'liactive': index === activeIndex}">
                        <router-link :to="item.path ">
                            <span>{{ item.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="center-right">
                <router-view></router-view>
            </div>
        </div>

        <!-- -->

    </div>
</template>

<script setup>
import IndexTop from '../components/indexTop.vue'
import { reactive,ref } from 'vue';
let activeIndex = ref(0)
const sidearr = reactive(
    [
        {
            path:'/myMember',
            name:'我的会员'
        },
        {
            path: '/myMessage',
            name: '企业资料'
        },
        {
            path: '/setTing',
            name: '账号设置'
        },
        {
            path: '/serverSub',
            name: '申报记录'
        },
        {
            path: '/myCeping',
            name: '测评记录'
        }
    ]
)
</script>
<style scoped lang="scss">
.personal-center {
    background-color: #f5f5f5;
    min-height: calc(100vh - 80px);
}

.personal-center-content {
    padding-top: 20px;
    margin: 80px auto 20px;
    width: 70%;
    display: flex;
    min-height: 500px;

    .center-left {
        width: 190px;
        margin-right: 20px;
        background: #fff;
        height: 250px;

        ul {
            margin: 0;
            padding: 0;
            width: 100%;
            list-style: none;
            display: flex;
            flex-direction: column;
            

            li {
                a {
                    font-size: 14px;
                    display: block;
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    text-decoration: none;
                    color: #333;
                }

            }

            .liactive{
                background-color: #ececec;
            }

            li:hover {
                background-color: #ececec;
            }
        }
    }

    .center-right {
        flex: 1;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
    }
}
</style>
