<script setup>
import { ref, onMounted, computed } from 'vue'
import { getUserInfo } from '../api/useroperation'
import { xuFei, shengJi } from '../api/finance'
import { ElMessageBox } from 'element-plus'
const dialogFormVisible = ref(false)
const dialogSjVisible = ref(false)
const uInfo = ref({})
const selectTime = ref('')
const selectJie = ref('')
const goShengji = () => {

    let param = {
        money: shegnjitotalMoney.value,
        type: selectJie.value
    }
    shengJi(param).then(res => {
        console.log(res)
        ElMessageBox.alert('提交升级申请成功', '提示', {
            confirmButtonText: '确定',
            callback: () => {
                dialogSjVisible.value = false
            }
        })
    })
}
const goXuFei = () => {
    dialogFormVisible.value = false
    let param = {
        money: totalMoney.value,
        type: selectTime.value
    }
    xuFei(param).then(res => {
        console.log(res)
        ElMessageBox.alert('提交续费申请成功', '提示', {
            confirmButtonText: '确定',
            callback: () => {
                dialogFormVisible.value = false
            }
        })
    })
}

onMounted(() => {
    getUserInfo().then(res => {
        uInfo.value = res.data
        console.log(res.data)
    })
})

const totalMoney = computed(() => {
    let money = 0;
    if (uInfo.value.id == 2) {
        money = 800;
    } else if (uInfo.value.id == 3 || uInfo.value.id == 4) {
        money = 8000;
    }
    return money * selectTime.value
})

const shegnjitotalMoney = computed(() => {
    let money = 0;
    if (selectJie.value == 2) {
        money = 800;
    } else if (selectJie.value == 3 || selectJie.value == 4) {
        money = 7200;
    }
    return money
})
</script>

<template>
    <div class="myMember">
        <h2>我的会员</h2>
        <el-divider />
        <div class="member-list">
            <div class="left">
                <div class="item">
                    <span>会员等级</span>
                    <span>{{ uInfo.title }}</span>
                </div>
                <div class="item">
                    <span>到期时间</span>
                    <span v-if="uInfo.level_expiration_time == null">无期限</span>
                    <span v-else>{{ uInfo.level_expiration_time }}</span>
                </div>
            </div>
            <div class="right">
                <el-button size="small" type="primary" plain @click="dialogSjVisible = true">会员升级</el-button>
                <el-button v-if="uInfo.id > 1" size="small" type="success" plain style="margin-left: 0;"
                    @click="dialogFormVisible = true">会员续费</el-button>
            </div>
        </div>

        <el-dialog v-model="dialogSjVisible" title="会员升级" width="500">
            <el-divider style="margin: 0;" />
            <div style="display: flex;margin-top: 50px;align-items: center;">
                <span style="font-weight: bold;flex-shrink: 0;">选择升级类型：</span>
                <el-select v-model="selectJie" placeholder="请选择升级类型" style="width: 150px;">
                    <el-option v-if="uInfo.id < 2" label="V2 个人会员" value="2" />
                    <el-option v-if="uInfo.id < 3" label="V3 企业会员" value="3" />
                    <el-option v-if="uInfo.id < 4" label="V8 黑金会员" value="4" />
                </el-select>
            </div>
            <div style="display: flex;margin-top: 50px;align-items: center;">
                <span style="font-weight: bold;flex-shrink: 0;">应付金额：</span>
                <span>{{ shegnjitotalMoney }}元</span>
                <span style="font-weight: bold;color:red;margin-left: 10px;">备注：支付后请联系客服人员开通服务</span>
            </div>
            <div style="height:188.69px;display: flex;margin-top: 50px;align-items: center;">
                <span style="font-weight: bold;">请扫描二维码支付：</span>
                <img src="@/static/m_code.png" style="" width="150px" height="100%;" />
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogSjVisible = false">取消</el-button>
                    <el-button type="primary" @click="goShengji()">
                        提交升级申请
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible" title="会员续费" width="500">
            <el-divider style="margin: 0;" />
            <div style="display: flex;margin-top: 50px;align-items: center;">
                <span style="font-weight: bold;flex-shrink: 0;">选择续费时长：</span>
                <el-select v-model="selectTime" placeholder="请选择续费时长" style="width: 150px;">
                    <el-option label="1年" value="1" />
                    <el-option label="2年" value="2" />
                    <el-option label="3年" value="3" />
                </el-select>
            </div>
            <div style="display: flex;margin-top: 50px;align-items: center;">
                <span style="font-weight: bold;flex-shrink: 0;">应付金额：</span>
                <span>{{ totalMoney }}元</span>
                <span style="font-weight: bold;color:red;margin-left: 10px;">备注：支付后请联系客服人员开通服务</span>
            </div>
            <div style="height:188.69px;display: flex;margin-top: 50px;align-items: center;">
                <span style="font-weight: bold;">请扫描二维码支付：</span>
                <img src="@/static/m_code.png" style="" width="150px" height="100%;" />
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取消</el-button>
                    <el-button type="primary" @click="goXuFei()">
                        提交续费申请
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>


<style scoped lang="scss">
.myMember {
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0;
        padding: 0;
    }

    .member-list {
        display: flex;

        .left {
            display: flex;
            flex-direction: column;
            flex: 1;

            .item {
                margin-bottom: 10px;

                span:nth-child(1) {
                    width: 80px;
                    font-size: 14px;
                    margin-right: 10px;
                    line-height: 22px;
                    color: #333;
                }

                span:nth-child(2) {
                    font-weight: bold;
                    font-size: 14px;
                    color: #333;
                }
            }

        }

        .right {
            flex-direction: column;
            justify-content: space-between;
            display: flex;
        }
    }

    .m-img {
        width: 15px;
        height: 15px;
        margin-left: 5px;
    }
}
</style>